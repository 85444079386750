.App {
   background-color: $txt_color;
  .hero {
    height: 100vh;
    box-sizing: border-box;
    position: relative;
    background: $txt_color;
    padding: 0 10%; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    background: url('../images/hero-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    &__title {
      margin: 0;
      position: relative;
      z-index: 2;
      filter: drop-shadow(0 0 0.75rem $featured);
      color: $white;
      @include subtitle($size: 55px, $color: $white, $align: left);
      font-weight: 300;
      line-height: 1;
      span {
        display: block;
        font-weight: 600;
        font-size: 60px;
        &:last-of-type{
          font-weight: 300;
          font-size: 50px;
          

        }
      }
      &::before {
        content: "";
        width: 1px;
        height: 100%;
        display: inline-block;
        position: absolute;
        background: $white;
        left: -30px;
      }
    }
    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
      background:linear-gradient(0deg, rgba(32,32,32,0.8) 0%, rgba(32,32,32,0.5) 40%, rgba(32,32,32,0) 100%);
      width: 100%;
      height: 100%;
    }
  }
  .projects {
    min-height: 500px;
    h2 {
      @include subtitle($color: $white, $size: 40px);
      width: 100%;
      margin-bottom: 40px;
    }
  }
  .blog {
    min-height: 500px;
    background: url('../images/pattern-1.png'), $txt_color;
    padding: 100px 50px 0;
    h2 {
      @include subtitle($color: $white, $size: 40px);
      width: 100%;
      margin-bottom: 0px;
    }
  }
} 
