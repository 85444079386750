.socialmedia {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  a {
    padding: 0;
    img {
      width: 50px;
      height: 50px;
      
      margin: 10px;
    }
    
    &.Medium {
      img {
        box-sizing: border-box;
        padding: 5px;
      }
    }
    &.Twitter {
      img {
        box-sizing: border-box;
        padding: 3px;
      }
    }
    &.Github {
      img {
        box-sizing: border-box;
        padding: 1px;
      }
    }
    &:hover {
      img {
        transform: scale(1.2) rotate(15deg);
      }
    }
  }
  margin: 20px 0 20px 0;
}