.header {
  height: 100px;
  width: 100%;
  max-width: 100vw;
  display: flex;
  position: absolute;
  top: 100vh;
  z-index: 99;
  justify-content: space-evenly;
  align-items: center;
  background: url('../images/header-bg-3.png') no-repeat right center, $txt_color;
  transition: background 0.2s ease-in-out;
  &.fixed {
    position: fixed;
    top: 0;
     background: url('../images/header-bg-3.png') no-repeat right center, $white;
    box-shadow: 0px 10px 10px -5px rgba(0,0,0,0.5);
    #menu li a {
      color: $featured;
    }
  }
  //background: linear-gradient(180deg, rgba(32,32,32,0.9) 0%, rgba(255,255,255,0) 100%);
  &__data {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &--logo {
      width: 50px;
      height: 50px;
      animation: logo-spin infinite 20s linear;
      img {
        max-width: 100%;
      }
    }
    span {
      @include subtitle($size: 30px, $color: $primary_color);
      letter-spacing: 2px;
    }
  }
  &__menu {
    opacity: 0;
    transition: all 1s ease-in;
    &.animate__bounce {
      opacity: 1;
    }
    #menu {
      display: flex;
      list-style: none;
     
      li {
        margin: 0 20px;
        a {
          @include subtitle;
          color: $white;
          text-decoration: none;
          padding: 10px 20px;
          &:hover, &.active {
            color: $white;
          }
          &.offset {  
            box-shadow: 
              0.3em 0.3em 0 0 $primary_color,
              inset 0.3em 0.3em 0 0 $primary_color;
            
            &:hover,
            &.active,
            &:focus {
              box-shadow: 
                0 0 0 0 $featured,
                inset 6em 3.5em 0 0 $featured;
            }
          }
        }
        
      }
    }
  }
}
@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}