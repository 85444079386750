.skills {
  padding: 100px 50px 0;
  margin-bottom: -50px;
  background: url('../images/pattern-2.png') no-repeat bottom left, $txt_color;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  opacity: 0;
  h2 {
    @include subtitle($color: $white, $size: 40px);
    width: 100%;
    margin-bottom: 70px;
  }
  &-container {
  width: 500px;
	margin: 0 auto 0;
	padding: 0;
	list-style: none;
	
    &__bar {
      height: 20px;
      margin-bottom: 50px;
      position: relative;
      background: #e9e5e2;
      background-image: linear-gradient(top, #e1ddd9, #e9e5e2);
      box-shadow: 0 1px 0px #bebbb9 inset, 0 1px 0 #fcfcfc;	
      border-radius: 10px;
      break-inside: avoid-column;
      transition: 1s ease-in-out;
    }
    
    &__status {
      height: 18px;
      margin: 1px 2px;
      border-radius: 10px;
      box-shadow: 0 1px 0px #fcfcfc inset, 0 1px 0 #bebbb9;	
      position: absolute;
      top: 0;
    }
    
    &__text {
      position: relative;
      top: -40px;
      @include copy($color: $white, $size: 20px);
    }
  }
}
