@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Roboto+Condensed:wght@300;400;700&display=swap');

//Variables
$primary_font: 'Roboto Condensed', sans-serif;
$secondary_font: 'Dancing Script', cursive;
$primary_color: #A8E6CE;
$secondary_color: #DCEDC2;
$light_bg: #FFD3B5;
$deep_bg: #FFAAA6;
$featured: #FF8C94;
$txt_color: #363636;
$white: #fff;

@mixin title($size: 40px, $color: $primary_color, $align: center){
  font-size: $size;
  font-family:$secondary_font;
  color: $color;
  text-align: $align;
}
@mixin subtitle($size: 20px, $color: $primary_color, $align: center) {
  font-size: $size;
  font-family: $primary_font;
  color: $color;
  text-align: $align;
  text-transform: uppercase;
}
@mixin copy($size: 16px, $color: $txt_color, $align: left){
  font-size: $size;
  font-family: $primary_font;
  color: $color;
  text-align: $align;
}
body {
  margin: 0;
  font-family: $primary_font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $light_bg;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import 'assets/sass/App';
@import 'assets/sass/Footer.scss';
@import 'assets/sass/Header.scss';
@import 'assets/sass/PostCard.scss';
@import 'assets/sass/SocialMedia';
@import 'assets/sass/skills';
@import 'assets/sass/Projects';
@import 'assets/sass/MediaQueries';