.footer {
  padding: 40px;
  background: url('../images/pattern-left-bg.gif') no-repeat, url('../images/pattern-right-bg.gif') no-repeat 100% center, #FFA8A5 ;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  h2, span {
    @include subtitle($color: $white, $size: 17px); 
    font-weight: 300;
  }
  h2 {
    font-size: 25px;
    font-weight: 600;
    margin: 10px 0 0;
    position: relative;
    &::before, &::after {
      content: "";
      display: inline;
      width: 100%;
      height: 1px;
      background: $white;
      overflow: hidden;
      position: absolute;
    }
    &::before {
      left: 0;
      top: -5px;
    }
    &::after {
      right: 0;
      bottom: -5px;
    }
  }
}