@media screen and (max-width: 1300px){
  .projects {
    .card {
      max-width: 40%;
    }
  }
  #blogs {
    .posts {
      .blog-card{
        width: 90%; 
      }
    }
  }
}
@media screen and (max-width: 1100px){
  #skills {
    .skills-container {
      width: 40%;
    }
  }
}
@media screen and (max-width: 768px){
  header {
    height: auto !important;
    &.fixed {
      #menu li a {
        color: $txt_color !important;
        background-color: rgba($color: #fff, $alpha: .7);
      }
    }
    #menu {
      //flex-direction: column;
      padding: 0;
      li {
        margin: 0 10px !important;
        a {
          font-size: 15px !important;

        }
      }
    }
  }
  .hero {
    box-sizing: border-box;
    max-height: 100vh;
    &__title {
      font-size: 40px !important;
      span {
        font-size: 40px !important;
      }
    }
  }
  #skills {
    padding: 100px 20px 0;
    background-size: 100px;
    h2 {
      font-size: 30px;
    }
  }
  #projects {
    padding: 100px 20px 0;
    h2 {
      font-size: 30px;
    }
    .card {
      max-width: 100%;
    }
  }
  #blogs {
    padding: 100px 20px 0;
    h2 {
      font-size: 30px;
    }
    .posts {
      padding: 10px;
      .blog-card {
        .description {
          h2 {
            font-size: 20px;
          }
          .read-more {
            margin: 0;
          }
        }
      }
    }
  }
  .footer {
    padding: 20px;
 
    background: url('../images/pattern-right-bg.gif') no-repeat center 85%, #FFA8A5 ;
    span {
      margin-top: 110px;
    }
  }
}