.posts {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
  max-width: 1500px;
  margin: 0 auto;
  padding: 50px 15px 70px;
  opacity: 0;
}
.blog-card {
  display: flex;
  flex-direction: column;
  width: 45%;
  margin: 20px auto;
  box-shadow: 0 3px 7px -1px rgba($txt_color, .1);
  background: $white;
  line-height: 1.4;
  border-radius: 5px;
  overflow: hidden;
  z-index: 0;
  cursor: pointer;
  a {
    color: inherit;
    &:hover {
      color: $primary_color;
    }
  }
  &:hover {
    .photo {
      transform: scale(1.3) rotate(3deg);
    }
  }
  .meta {
    position: relative;
    z-index: 0;
    height: 200px;
  }
  .photo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    transition: transform .2s;
  }
  .details,
  .details ul {
    margin: auto;
    padding: 0;
    list-style: none;
  }

  .details {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -110%;
    margin: auto;
    transition: left .2s;
    background: rgba(#000, .6);
    color: $white;
    padding: 10px;
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
    ul li {
      display: inline-block;
    }
    li{
      margin-bottom: 10px;
    }
    .author:before {
      margin-right: 10px;
      content: "";
      background: url('../images/icons/user.png') center center no-repeat;
      background-size: contain;
      width: 25px;
      display: inline-block;
      height: 20px;
      vertical-align: middle;
    }

    .date:before {
      margin-right: 10px;
      content: "";
      background: url('../images/icons/calendar.png') center center no-repeat;
      background-size: contain;
      width: 25px;
      display: inline-block;
      height: 20px;
      vertical-align: middle;
    }

    .tags {
      &:before {
        content: "";
        margin-right: 10px;
        background: url('../images/icons/bookmark.png') center center no-repeat;
        background-size: contain;
        width: 25px;
        display: inline-block;
        height: 20px;
        vertical-align: middle;
      }
      li {
        margin-right: 4px;
      }
    }
    .tags__list {
        padding-left: 36px;
        margin-top: -20px;
        padding-right: 18px;
        list-style: disc;
      }
  }
  .description {
    padding: 1rem;
    background: $white;
    position: relative;
    z-index: 1;
    h1,
    h2 {
      @include subtitle($size: 20px, $color: $primary_color, $align: left);
      font-weight: 500;
      margin-top: 0;
    }
    .read-more {
      text-align: right;
      a {
        color: $featured;
        display: inline-block;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        margin-left: -10px;
        &:after {
          content: "";
          background: url('../images/icons/right-arrow.png') center center no-repeat;
          background-size: contain;
          opacity: 0;
          width: 25px;
          display: inline-block;
          height: 20px;
          vertical-align: sub;
          margin-left: -10px;
          transition: margin .3s, opacity .3s;
        }

        &:hover:after {
          margin-left: 5px;
          opacity: 1;
        }
      }
    }
  }
  p {
    position: relative;
    margin: 1rem 0 0;
    &:first-of-type {
      margin-top: 1.25rem;
      &:before {
        content: "";
        position: absolute;
        height: 5px;
        background: $featured;
        width: 35px;
        top: -0.75rem;
        border-radius: 3px;
      }
    }
  }
  &:hover {
    .details {
      left: 0%;
    }
  }


  @media (min-width: 640px) {
    flex-direction: row;
    max-width: 700px;
    .meta {
      flex-basis: 40%;
      height: auto;
    }
    .description {
      flex-basis: 60%;
      &:before {
        transform: skewX(-3deg);
        content: "";
        background: #fff;
        width: 30px;
        position: absolute;
        left: -10px;
        top: 0;
        bottom: 0;
        z-index: -1;
      }
    }
    &.alt {
      flex-direction: row-reverse;
      .description {
        &:before {
          left: inherit;
          right: -10px;
          transform: skew(3deg)
        }
      }
      .details {
        padding-left: 25px;
      }
    }
  }
}